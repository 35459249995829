@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

.display8-outer-container {
    margin: 12rem 0 6rem;
}

.display8-title {
    /* font-family: 'Italiana', serif; */
    font-family: 'Oswald', sans-serif;
    font-size: 2.25rem;
    font-weight: 500;
    color: #444;
    margin: 0;
}

.display8-statement {
    font-size: 1.1rem;
    line-height: 1.6;
}

.display8-cards-container {}

.display8-card {
    margin: 3rem 0;
    border-radius: 8px;
    background-color: #f8f8f8;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.display8-card-image {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.display8-card-title-url-container {
    padding: 0 5% 5%;
}

.display8-card-title {
    font-size: 1.1rem;
    font-weight: 600;
}

.display8-card-url {
    text-decoration: none;
    color: #425466;
}
