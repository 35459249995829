.footer2-outer-container {
    padding: 0 0 4rem;
}

.footer2-divider-container-1 {
    position: relative;
    margin: 30% 0 0 -40%;
    width: 200vw;
    height: 10vh;
    background-color: #425466;
    transform: rotate(16deg);
    z-index: -1;
}

.footer2-logo {
    width: 10%;
    border-radius: 50%;
}

.footer2-location-container {
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
}

.footer2-location-icon {
    width: 5%;
    margin: 0 0 0 2.5%;
}

.footer2-locations {
    margin: 0 0 0 2.5%;
    padding: 0;
    display: flex;
    /* flex-wrap: wrap; */
    list-style-type: none;
}

.footer2-location {
    font-size: .9rem;
    font-weight: 500;
}

.footer2-copyright {
    padding: 0 0 0 3%;
    font-size: .9rem;
    font-weight: 500;
}

.footer2-links {
    margin: 2rem 0 0;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 4%;
    list-style-type: none;
}

.footer2-link {
    font-weight: 600;
    margin: 0 0 1rem;
    text-decoration: none;
    color: #425466;
}
