.menu2-outer-container {
    display: flex;
    justify-content: space-between;
    padding: 3% 6% 0;
}

.menu2-logo {
    width: 10%;
    border-radius: 50%;
}

.menu2-hamburger-icon {
    width: 10%;
}

.menu2-mobile-container {
    position: absolute;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-color: #425466;
}

.menu2-mobile-links {
    position: absolute;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.menu2-close-menu-icon {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
}

.menu2-mobile-link {
    margin: 0 0 3rem;
    font-weight: 500;
    font-size: 4rem;
    color: #fff;
    text-decoration: none;
}
