/* #F2E313 --> YELLOW*/
/* #0D0D0D --> BACKGROUND BLACK */

@import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.home-page-outer-container {
    opacity: 0;
    transition: opacity 0.85s ease-in-out;
}

.home-page-inner-container {
    position: relative;
    margin: 2.5rem 0 0;
    padding: 0 6%;
    overflow-x: hidden;
}

.home-page-hero-container {
    position: relative;
    width: 100%;
}

.home-page-hero-title {
    font-family: 'Italiana', serif;
    font-size: 3rem;
    /* color: #2d3e4f; */
    color: #444;
}

.home-page-hero-statement {
    margin: 0 0 2.5rem 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    line-height: 1.6;
}

.create-with-us-btn {
    font-size: .85rem;
    font-weight: 600;
    /* padding: .5rem 1.5rem; */
    padding: .5rem 1rem;
    border-radius: 25px;
    color: #fff;
    background-color: #425466;
    text-decoration: none;
}

.home-page-hero-main-content-divider-container-1 {
    position: relative;
    margin: -10% 0 0 -40%;
    width: 200vw;
    height: 15vh;
    background-color: #425466;
    transform: rotate(-33deg);
    z-index: -1;
}

.home-page-content-divider-container-1 {
    position: absolute;
    margin: 25% 0 0 -75%;
    width: 300vw;
    height: 75vh;
    background-color: #f4f4f4;
    transform: rotate(-33deg);
    z-index: -1;   
}
